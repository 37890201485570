@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: local("Poppins"),
    url("assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Light";
  font-style: normal;
  font-weight: 100;
  src: local("Poppins"),
    url("assets/fonts/Poppins/Poppins-Light.ttf") format("truetype");
}

html {
  scroll-behavior: smooth;
}
